import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Cheil Colombia 2020
    </span>
    <a class="logo float-right" href="#">
    <img src="assets/images/cheil.png" alt="Cheil">
    </a>
  `,
})
export class FooterComponent {
}
